import {paths, social_externalLinks} from "routes/paths"

export const InfoMaterialFranquiciados = [
    {
        url: paths.preciosFranquiciados.url,
        label: 'PRECIOS',
        target: '_self'
    },
    {
        url: paths.promosFranquiciados.url,
        label: 'PROMOS VIGENTES',
        target: '_self'
    },
    {
        url: paths.productosYcatalogosFranquiciados.url,
        label: 'PRODUCTOS Y CATÁLOGOS',
        target: '_self'
    },
    /*{
        url: 'https://drive.google.com/drive/folders/1ROJEf7-6Gqsughqq4eCpsPW0ZEdXsdcO?usp=sharing',
        label: '04 - PRODUCTOS TERCEROS',
        target: '_blank'
    },
    {
        url: 'https://drive.google.com/drive/folders/1Acts3F5QzCMNeuvDyyZmZFlHNc32DGon?usp=sharing',
        label: 'PRODUCTOS DE TERCEROS',
        target: '_blank'
    },*/
    {
        url: 'https://drive.google.com/drive/folders/1NxYnUGxjqGpq3rY3iL1iHHhbnkxIsPxT?usp=sharing',
        label: 'PICADAS',
        target: '_blank'
    },
    {
        url: 'https://drive.google.com/drive/folders/1tNYARwNOOGSsoyErlMgLy0nTUfIi__sW?usp=sharing',
        label: 'LOGOS, JINGLE Y MÚSICA',
        target: '_blank'
    },
    /*{
        url: 'https://drive.google.com/drive/folders/13o9zrVPw4fhP-GrbaRqtYQh4fB3fkMHj?usp=sharing',
        label: '07 - LOGOS',
        target: '_blank'
    },*/
    {
        url: paths.prensaFranquiciados.url,
        label: 'PRENSA Y TV',
        target: '_self'
    },
    {
        url: 'https://drive.google.com/file/d/1mS2lMPQHgbDLAGO0rgprp-ecKpqikOZf/view?usp=drive_link',
        label: 'G- LA',
        target: '_blank'
    },
    {
        url: 'https://drive.google.com/drive/folders/1ZltS5s62v25JakBElPKPevCJdxfwD4dN?usp=sharing',
        label: 'MATERIAL RRSS',
        target: '_blank'
    },
    {
        url: 'https://drive.google.com/file/d/12PLq-mk4dscFECLezo3kZ4VWB1w6awi0/view',
        label: 'RESPONSABLES DE ÁREA',
        target: '_blank'
    },
    /*{
        url: 'https://www.youtube.com/watch?v=IfN93ldEW5U&ab_channel=LuzAzul',
        label: 'AZULANDIA',
        target: '_blank'
    },
    {
        url: 'https://www.youtube.com/watch?v=9pMnAtSLmIw&list=PLOMCZGrDhncRgIILozGCnlFNBTTqYFjmD&ab_channel=LuzAzul',
        label: 'CANAL TV LUZ AZUL',
        target: '_blank'
    },*/
]